import styled from 'styled-components';
import { WindowLocation } from '@reach/router';
import { Icon, theme } from '@ovotech/element';

import { NewPlanSelectionDesktop } from './Desktop';
import { TopSummaryBanner } from './components/TopSummaryBanner';
import { NewPlanSelectionMobile } from './Mobile';

import { useModalContext } from '@contexts/ModalContext';
import { MixpanelEvents, Variant } from '@entities/enums';
import { mqLargeUp, mqMediumUp, mqSmallUp } from '@utils/mediaQuery';
import { Banner, BeyondBanner } from '@components/Banner';
import { useMediaIs } from '@hooks/useMediaIs';
import { useCustomMixpanel } from '@services/Mixpanel';
import { useQuotesPlatform } from '@hooks/useQuotesPlatform';
import { ProductCode } from '@src/types/Quote';
import { usePromoComponentsNew } from '@hooks/usePromoComponentsNew';
import { NewBasicPage } from '@components/layout';

const Container = styled.div`
  position: relative;
  padding-top: 10px;
  ${mqSmallUp(`
    padding-top: 0px
  `)};
  ${mqMediumUp(`
    padding-top: 100px;
  `)};
`;

const InclusionsContainer = styled.div`
  ${({ theme }) => `
    position: absolute;
    top: -200px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 10px;
    justify-content: center;
    align-items: flex-start;
    background-color: ${theme.core.color.brand.white};
    border-radius: ${theme.core.radius.max};
    width: 100%;
    padding: 18px 16px;
    height: 195px;
    max-height: 200px;
    ${mqSmallUp(`
      top: -140px;
      padding: 18px 36px;
      justify-content: space-between;
      height: 120px;
    `)};

    ${mqMediumUp(`
      top: -100px;
      height: 168px;
      justify-content: space-between;
      padding: 30px 30px;
    `)};

    ${mqLargeUp(`
      max-width: 1136px;
      padding: 30px 90px;
    `)};
  `};
`;

const InclusionWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    flex: 0 0 33.3333%;
    justify-content: center;
    align-items: center;

    svg {
      color: ${theme.core.color.brand.ovo};
    }

    ${mqSmallUp(`
      flex: 1;
    `)};
  `};
`;

const WrenchIconWrapper = styled(Icon)`
  margin: 2px;

  ${mqMediumUp(`
    margin: 3px;
  `)};
`;

const InclusionText = styled.div`
  text-align: center;
  margin-top: 4px;
  max-width: 95px;
  font-size: 12px;

  ${mqMediumUp(`
    margin-top: 17px;
    max-width: 130px;
    font-size: 16px;
  `)};
`;

const inclusionsList = [
  {
    icon: 'boiler-service',
    text: 'Annual boiler service',
  },
  {
    icon: 'energy-advice',
    text: 'Unlimited call outs',
  },
  {
    icon: 'clock',
    text: '24/7 emergency helpline',
  },
  {
    icon: 'boilerEngineer',
    text: 'Parts and labour included',
  },
  {
    icon: 'wrench',
    text: 'Boiler breakdown repair',
  },
];

interface PlanSelectionProps {
  location?: WindowLocation;
  forceDesktopComponent?: boolean;
  variant: Variant;

  onSelectCoverType(productName: ProductCode): void;
}

const DivTopSummary = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  ${mqMediumUp(`
    justify-content: space-between;
    flex-direction: row;
  `)};
`;

export const NewPlanSelection = ({
  forceDesktopComponent,
  location,
  variant,
  onSelectCoverType,
}: PlanSelectionProps) => {
  const { ModalComponent } = useModalContext();
  const { getBanner } = usePromoComponentsNew();

  const { track } = useCustomMixpanel();
  const { getQuoteByProductCode } = useQuotesPlatform();
  const completeQuote = getQuoteByProductCode(ProductCode.COMPLETE);

  const isMobile = useMediaIs('mobile', 'max');

  const handleUSPButtonClick = (inclusionText: string) => {
    track(MixpanelEvents.BUTTON_CLICK, {
      buttonId: `heroUSP-${inclusionText}`,
    });
  };

  const banner = getBanner(completeQuote);

  return (
    <>
      {location && (
        <NewBasicPage
          pageName="planDetails"
          location={location}
          quote={completeQuote}
          showTopPageBanner
        >
          <ModalComponent forceDesktopComponent={forceDesktopComponent} />
          <DivTopSummary id="top-summary">{banner ?? <TopSummaryBanner />}</DivTopSummary>
          <Container>
            <InclusionsContainer>
              {inclusionsList.slice(0, -1).map((inclusion, index) => {
                return (
                  <InclusionWrapper
                    key={index}
                    onClick={() => handleUSPButtonClick(inclusion.text)}
                  >
                    <Icon
                      name={inclusion.icon}
                      size={isMobile ? 24 : 44}
                      color={theme.core.color.brand.ovo}
                    />
                    <InclusionText>{inclusion.text}</InclusionText>
                  </InclusionWrapper>
                );
              })}
              <InclusionWrapper onClick={() => handleUSPButtonClick(inclusionsList[4].text)}>
                <WrenchIconWrapper
                  name={inclusionsList[4].icon}
                  size={isMobile ? 20 : 38}
                  color={theme.core.color.brand.ovo}
                />
                <InclusionText>{inclusionsList[4].text}</InclusionText>
              </InclusionWrapper>
            </InclusionsContainer>

            <NewPlanSelectionDesktop variant={variant} onSelectCoverType={onSelectCoverType} />
            <NewPlanSelectionMobile variant={variant} onSelectCoverType={onSelectCoverType} />
          </Container>

          {variant === Variant.BEYOND ? <BeyondBanner /> : <Banner />}
        </NewBasicPage>
      )}
    </>
  );
};
