import { navigate } from 'gatsby';
import { useEffect } from 'react';
import { WindowLocation } from '@reach/router';
import { useDecision } from '@optimizely/react-sdk';
import { useSetAtom } from 'jotai';

import { useUrlPageSelection } from '@hooks/useUrlPageSelection';
import { useCustomMixpanel } from '@services/Mixpanel';
import { OptimizelyFeatureFlag } from '@entities/optimizely';
import { NewPlanSelectionPage } from '@src/modules/PlanSelection/NewPlanSelectionPage';
import { Variant } from '@entities/enums';
import { isAgentAtom, repairAtom } from '@src/store/store';

export interface BeyondProps {
  location: WindowLocation;
}
export const NewBeyondQuotesplatform = (props: BeyondProps) => {
  const setIsAgent = useSetAtom(isAgentAtom);
  const setRepair = useSetAtom(repairAtom);

  const mixpanel = useCustomMixpanel();

  const [isStandalone95Excess] = useDecision(OptimizelyFeatureFlag.STANDALONE_95_EXCESS);

  const selectedPageFromUrl = useUrlPageSelection();

  useEffect(() => {
    if (!isStandalone95Excess) {
      navigate(`/`);
      return;
    }
  }, [isStandalone95Excess]);

  useEffect(() => {
    setIsAgent(false);
    setRepair(undefined);
    if (selectedPageFromUrl) {
      navigate(`/${selectedPageFromUrl}`);
      return;
    }
  }, [selectedPageFromUrl, setIsAgent, setRepair]);

  if (selectedPageFromUrl) {
    return null;
  }
  return (
    <NewPlanSelectionPage mixpanel={mixpanel} variant={Variant.BEYOND} location={props.location} />
  );
};
