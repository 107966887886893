import { navigate } from 'gatsby';
import React, { useEffect, useRef } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Mixpanel } from 'mixpanel-browser';
import { useSetAtom } from 'jotai';

import { NewPlanSelection } from './NewPlanSelection';

import { ROUTE_DETAILS } from '@constants/routes';
import { MixpanelEvents, Variant } from '@entities/enums';
import { ModalContextProvider } from '@contexts/ModalContext';
import { selectedProductAtom } from '@src/store/store';
import { useAppContext } from '@contexts/AppContext';
import { ProductCode } from '@src/types/Quote';

interface Props extends RouteComponentProps {
  mixpanel: Mixpanel;
  forceDesktopComponent?: boolean;
  variant: Variant;
}

export const NewPlanSelectionPage: React.FC<Props> = ({
  mixpanel,
  location,
  forceDesktopComponent,
  variant,
}) => {
  const { verifyNoSignupInLocalStorage } = useAppContext();

  const setSelectedProduct = useSetAtom(selectedProductAtom);
  const mixpanelRef = useRef(mixpanel);
  const verifyNoSignupInLocalStorageRef = useRef(verifyNoSignupInLocalStorage);

  useEffect(() => {
    setSelectedProduct(undefined);
    mixpanelRef.current.track(MixpanelEvents.PAGE_HOME, { pageName: null });
    verifyNoSignupInLocalStorageRef.current();
  }, [setSelectedProduct]);

  const setCoverTypeAndNavigate = (productCode: ProductCode): void => {
    setSelectedProduct(productCode);
    mixpanel.track(MixpanelEvents.SELECT_PLAN, { productCode });
    navigate(ROUTE_DETAILS);
  };

  return !location ? null : (
    <ModalContextProvider>
      <NewPlanSelection
        forceDesktopComponent={forceDesktopComponent}
        onSelectCoverType={setCoverTypeAndNavigate}
        location={location}
        variant={variant}
      />
    </ModalContextProvider>
  );
};
