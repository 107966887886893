import styled from 'styled-components';

import { ExtendedBanner } from '@components/Banners/ExtendedBanner';
import { useQuotesPlatform } from '@hooks/useQuotesPlatform';

const TopBannerWrapper = styled.div`
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  margin-bottom: ${({ theme }) => theme.core.space[6]};
`;

export const TopSummaryBanner = () => {
  const { allQuotes } = useQuotesPlatform();

  const lowestPricePlan = allQuotes?.reduce((lowest, plan) => {
    return plan.price.standard.monthly < lowest.price.standard.monthly ? plan : lowest;
  }, allQuotes[0]);

  if (!lowestPricePlan?.price.standard.monthly) return null;

  return (
    <TopBannerWrapper>
      <ExtendedBanner lowestPrice={lowestPricePlan.price.standard.monthly} />
    </TopBannerWrapper>
  );
};
